import React, { useEffect, useState } from 'react';
import functions from '../util/functions';

const ThemeContext = React.createContext();

export const ThemeConsumer = ThemeContext.Consumer;

export const ThemeProvider = (props)=> {

    const [toastContent, setToastContent] = useState(false)
    const [activeMenu, setActiveMenu] = useState(false)

    useEffect(()=>{

        if(toastContent){
            setTimeout(() => {
                setToastContent(false)
            }, 5000);
        }

    }, [toastContent])

    const notify=(title, body)=>{
        setToastContent(functions.formatToast(title, body))
    }

    return (
           <ThemeContext.Provider value={
               {
                   toastContent,
                   activeMenu,
                   setToastContent,
                   notify,
                   setActiveMenu
               }
               }>
               {props.children}
           </ThemeContext.Provider>
        );
    
}

export default ThemeContext;