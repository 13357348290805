import React, { useEffect, useState } from 'react';
import ajaxSystem from '../util/remote/ajaxSystem';

const FinderContext = React.createContext();

export const FinderConsumer = FinderContext.Consumer;

export const FinderProvider = (props)=> {

  const [hint, setHint] = useState("");
  const [searchResults, setSearchResults] = useState("404");



const getSearchResults=async()=>{
    setSearchResults(false)
    const server_response = await ajaxSystem.fetchSearchResults(hint);
    if(server_response.status==="OK"){
        setSearchResults(server_response.details)
    }else{
        setSearchResults("404")
    }
}
    
    return (
           <FinderContext.Provider value={
               {
                  hint,
                  searchResults,
                  setHint,
                  getSearchResults
               }
               }>
               {props.children}
           </FinderContext.Provider>
        );
    
}

export default FinderContext;