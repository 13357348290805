import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import BatchContext from "../../Context/BatchContext";
import ActivityLoader from "../Common/ActivityLoader";
import DocumentContext from "../../Context/DocumentContext";
import useStateCallback from "../../util/customHooks/useStateCallback";
import NewBatch from "../Documents/NewBatch";
import functions from "../../util/functions";
import FormGenerator from "../Documents/FormGenerator";
import SerialFinder from "../Documents/SerialFinder";

const NiraMenu = () =>{

    const {batchList, getBatches, setBatchInfo, setActiveBatch, activeBatchId} = useContext(BatchContext);
    const {setCurrentPage, setData, setFinder} = useContext(DocumentContext);
    const [modal, setModal] = useStateCallback(false)

    useEffect(()=>{
        if(activeBatchId){
            setCurrentPage(1);
            setData({current_page: 1, batch_id: activeBatchId})
        }
    }, [activeBatchId])

    useEffect(()=>{
        if(!batchList){
            getBatches();
        }
    }, [])


    const handleBatchSelect=(batchData, batchId)=>{
        setBatchInfo(batchData);
        setActiveBatch(batchId);
        setFinder(false)
    }

    const handleAddBatch=()=>{
        setModal(false, ()=>{
            setModal(<NewBatch/>, ()=>{
                functions.openPopup("modal-new-batch")
            })
        });
    }
    

    const handleFormGen=()=>{
        setModal(false, ()=>{
            setModal(<FormGenerator />, ()=>{
                functions.openPopup("modal-form-gen")
            })
        });
    }

    const handlePrint = () => {
        const iframe = document.getElementById('form-screen');
        if (iframe) {
          const iframeWindow = iframe.contentWindow;
          if (iframeWindow) {
            iframeWindow.print();
          }
        }
      };


      const handleFinder=()=>{
        setModal(false, ()=>{
            setModal(<SerialFinder />, ()=>{
                functions.openPopup("modal-serial-finder")
            })
        });
    }

    return <><div className="gallery-menu-container">

    <div data-scrollbar="true" data-height="100%" data-skip-mobile="true">
    
    <div className="gallery-menu">
    <div className="gallery-menu-header"><b>NIRA Profile</b></div>
    <div className="gallery-menu-item"><Link to="#" className="gallery-menu-link" onClick={handleAddBatch}><i className="fa fa-fw fa-edit me-1"></i> New Batch</Link></div>
    <div className="gallery-menu-item"><Link to="#" className="gallery-menu-link" onClick={handleFormGen}><i className="fa fa-fw fa-file-alt me-1"></i> Form Generator</Link></div>
    <div className="gallery-menu-item"><a href="#" className="gallery-menu-link" onClick={handlePrint}><i className="fa fa-fw fa-print me-1"></i> Print Forms</a></div>
    <div className="gallery-menu-header">Utilities</div>
    <div className="gallery-menu-item"><Link to="#" className="gallery-menu-link" onClick={handleFinder}><i className="fa fa-fw fa-search me-1"></i> Serial Finder</Link></div>
    <div className="gallery-menu-header">Batch Listing</div>
    {!batchList && <ActivityLoader/>}
    {batchList === '404' && <p  className="gallery-menu-header">No batch found</p>}
        <div className="table-responsive overflow-auto" style={{maxHeight:'250px'}}>
            {Array.isArray(batchList) && batchList.map((item, key)=>
                <div className="gallery-menu-item" key={key} >
                    <Link to="#" className={`gallery-menu-link ${activeBatchId === item.id ? 'active' : ''}`} onClick={()=>handleBatchSelect(item, item.id)}>
                        <i className="fa fa-fw fa-folder me-1"></i> {item.batch_name}
                        </Link></div>
            )}
        </div>
    </div>
    
    </div>
    
    </div>
    {modal}
    </>
}

export default NiraMenu;