import { useContext } from "react";
import Toaster from "../Common/Toaster";
import LeftSideBar from "./LeftSideBar";
import TopBar from "./TopBar";
import ThemeContext from "../../Context/ThemeContext";

const AppContainter = (props)=>{

    const {toastContent} = useContext(ThemeContext);
    //app-sidebar-minified
    return <div id="app" className="app app-footer-fixed">
                <TopBar/>
                <LeftSideBar/>
                <div className="toasts-container">
                   {toastContent && <Toaster title={toastContent.title} body={toastContent.body}/>}
                </div>


                <div id="content" className={`app-content ${props.wideView && 'p-0'}`}>
                    {!props.hideHeaders && <>
                    <div className="d-flex align-items-center mb-3">
                        <div>
                            <h1 className="page-header mb-3">{props.title} <small>{props.description}</small></h1>
                        </div>
                        <div className="ms-auto">
                            {props.buttons}
                        </div>
                    </div>
                    <div className="mb-md-4 mb-3 d-md-flex">
                      {props.actionList}
                    </div>
                    </>}
                    {props.children}
                </div>

                <div id="footer" className="app-footer">
                        &copy; DAV CONSULTS & IT SOLUTIONS. Powered by: Digital Ocean
                </div>
            </div>
    

}


export default AppContainter;