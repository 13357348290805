import React, { useContext, useEffect, useState } from 'react';
import functions from '../../util/functions';
import NoData from '../Common/NoData';
import DocumentContext from '../../Context/DocumentContext';
import BatchContext from '../../Context/BatchContext';

const NiraForm = () => {
  const {currentPage, finder} = useContext(DocumentContext);
  const {activeBatchId} = useContext(BatchContext);
  const [url, setUrl] = useState(functions.getHost() + 'document.view?batch_id='+ activeBatchId +'&page=' + currentPage + '&token=99');
  useEffect(()=>{
         if(finder){
                setUrl(functions.getHost() + 'document.view?batch_id='+ activeBatchId +'&page=' + currentPage + '&token=99&finder=' + finder);
          }else{
                setUrl(functions.getHost() + 'document.view?batch_id='+ activeBatchId +'&page=' + currentPage + '&token=99');
          }
  }, [finder, activeBatchId, currentPage])


  return (
    <div>
      {(activeBatchId || finder) && <iframe title="NIRA FORM" id="form-screen" src={url} width="100%" height="700px" />}
      {!activeBatchId && !finder && <NoData message={"No forms to display, select batch with forms then try again"}/>}
      {/* {!DocumentList && activeBatchId && <ActivityLoader/>} */}
    </div>
  );
};

export default NiraForm;
