import React from 'react';
import image  from '../../Images/no_data.png';
const NoData = ({message}) => {
  return (
    <div style={styles.nodata}>
      <img
        src={image}
        style={{
          width: '20%',
        }}
        alt='No data'
      />
      <span style={styles.nodataTitle}>Oops! </span>

      <span style={styles.nodataText}>{message}</span>
    </div>
  );
};

const styles ={
  nodata: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAign: 'center',
  },
  nodataTitle: {
    fontSize: '30px',
    color: '#ffbb38',
    textAlign: 'center',
  },
  nodataText: {
    fontSize: '20px',
    lineHeight: 22,
    color: '#003679',
    textAlign: 'center',
  },
};

export default NoData;
