import { Link, useNavigate} from 'react-router-dom'
import logo from '../../Images/logo-dp.png'
import { useContext, useState } from 'react'
import FinderContext from '../../Context/FinderContext'
import profileImage from '../../Images/avatar.png';
import DocumentContext from '../../Context/DocumentContext';
const TopBar = (props)=>{

    const navigation = useNavigate();


    const onLogout=()=>{
       
            window.localStorage.removeItem('uppc@user')
            window.location.replace('/');
      }

      const {hint, setHint, getSearchResults} = useContext(FinderContext)
      const {FSB, fetchFSB} = useContext(DocumentContext);

      const handleSearch = (e)=>{
        e.preventDefault();
        getSearchResults()
        navigation("/finder")
      }
     
 
    return  <div id="header" className="app-header">
    
    <div className="mobile-toggler">
    <button type="button" className="menu-toggler" data-toggle="sidebar-mobile">
    <span className="bar"></span>
    <span className="bar"></span>
    </button>
    </div>
    
    
    <div className="brand">
    <div className="desktop-toggler">
    <button type="button" className="menu-toggler" data-toggle="sidebar-minify">
    <span className="bar"></span>
    <span className="bar"></span>
    </button>
    </div>
    <Link to="/" className="brand-logo">
    <img src={logo} alt="" />
    </Link>
    </div>
    
    
    <div className="menu">
    <form className="menu-search" method="POST" name="header_search_form" onSubmit={handleSearch}>
    <div className="menu-search-icon"><i className="fa fa-search"></i></div>
    <div className="menu-search-input">
      <input type="text" className="form-control" placeholder="System search..." value={hint} onChange={(e)=>setHint(e.target.value)}/>
    </div>
    </form><span className='text-danger'><Link to="#" onClick={fetchFSB}> <b>F.S.B:</b> {FSB}</Link></span>
    <div className="menu-item dropdown">
    <a href="#" data-bs-toggle="dropdown" data-display="static" className="menu-link">
    <div className="menu-icon"><i className="fa fa-bell nav-icon"></i></div>
    <div className="menu-label">1</div>
    </a>
    <div className="dropdown-menu dropdown-menu-right dropdown-notification">
    <h6 className="dropdown-header text-dark mb-1">Notifications</h6>
    <a href="#" className="dropdown-notification-item">
        <div className="dropdown-notification-icon">
        <i className="fa fa-info fa-lg fa-fw text-warning"></i>
        </div>
        <div className="dropdown-notification-info">
          <div className="title">Your Form Size Balance (FSB) is now {FSB}. This represents the remaining maximum number of forms that can be generated.</div>
          <div className="time">just now</div>
        </div>
        <div className="dropdown-notification-arrow">
        <i className="fa fa-chevron-right"></i>
        </div>
    </a>
    <a href="#" className="dropdown-notification-item">
        <div className="dropdown-notification-icon">
        <i className="fa fa-receipt fa-lg fa-fw text-success"></i>
        </div>
        <div className="dropdown-notification-info">
          <div className="title">NIRA Profile has been accessed.</div>
          <div className="time">just now</div>
        </div>
        <div className="dropdown-notification-arrow">
        <i className="fa fa-chevron-right"></i>
        </div>
    </a>
   
    
    
    <div className="p-2 text-center mb-n1">
      <a href="#" className="text-dark text-opacity-50 text-decoration-none">See all</a>
    </div>
    </div>
    </div>
    <div className="menu-item dropdown">
    <a href="#" data-bs-toggle="dropdown" data-display="static" className="menu-link">
    <div className="menu-img online">
    <img src={profileImage} alt="" className="ms-100 mh-100 rounded-circle" />
    </div>
    <div className="menu-text"><span className="__cf_email__" data-cfemail="285b4d49465c404d454d684f45494144064b4745">Admin</span></div>
    </a>
    <div className="dropdown-menu dropdown-menu-right me-lg-3">
    <Link to="#" className="dropdown-item d-flex align-items-center">Profile <i className="fa fa-user-circle fa-fw ms-auto text-dark text-opacity-50"></i></Link>
    {/* <a className="dropdown-item d-flex align-items-center" href="#">Inbox <i className="fa fa-envelope fa-fw ms-auto text-dark text-opacity-50"></i></a> */}
    {/* <a className="dropdown-item d-flex align-items-center" href="#">Calendar <i className="fa fa-calendar-alt fa-fw ms-auto text-dark text-opacity-50"></i></a> */}
    <Link to="/configurations" className="dropdown-item d-flex align-items-center">Setting <i className="fa fa-wrench fa-fw ms-auto text-dark text-opacity-50"></i></Link>
    <div className="dropdown-divider"></div>
    <Link className="dropdown-item d-flex align-items-center" to="#" onClick={onLogout}>Log Out <i className="fa fa-toggle-off fa-fw ms-auto text-dark text-opacity-50"></i></Link>
    </div>
    </div>
    </div>
    
    </div>
}

export default TopBar