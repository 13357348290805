import React from 'react';
import {Vortex as Loader}  from 'react-loader-spinner';

const ActivityLoader=(props)=> {
  
    const types=['Audio', 'BallTriangle', 'Bars', 'Blocks', 'Circles', 'CirclesWithBar', 'ColorRing', 'Comment', 'Discuss', 'Dna', 'FallingLines', 'FidgetSpinner', 'Grid', 'Hearts', 'InfinitySpin', 'LineWave', 'MagnifyingGlass', 'MutatingDots', 'Oval', 'ProgressBar', 'Puff', 'Radio', 'RevolvingDot', 'Rings', 'RotatingLines', 'RotatingSquare', 'RotatingTriangles', 'TailSpin', 'ThreeCircles', 'ThreeDots', 'Triangle', 'Vortex', 'Watch']
    const type=props.type || 0
    const height=props.height || 80
    const width=props.width || 80
    const color=props.color || "#FF4800"
    const customClass=props.customClass || " col-12 text-center "

     
    
      return (
        <div className={customClass}>
          
             <Loader
                visible={true}
                type={types[type]}
                color={color}
                height={height}
                width={width}
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={['red', 'green', '#003679', '#fab93b', 'orange', 'purple']}

            />
        </div>

      );
    
 
  
}

export default ActivityLoader