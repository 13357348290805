import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
    async fetchBatches()
    {
        let response = await apiCall("document/batch/list_batches", [])
        return response;

    },

    async fetchDocuments(batch_id, page)
    {
        const data = {
            "batch_id":batch_id,
            "page": page
        }
        let response = await apiCall("document.view", data)
        return response;

    },

    async postNewBatch(batch_name, start_serial)
    {
        const data = {
            "batch_name":batch_name,
            "start_serial": start_serial
        }
        let response = await apiCall("document/batch/create", data)
        return response;

    },

    async generateForms(quantity, batch_id)
    {
        const data = {
            "quantity":quantity,
            "batch_id": batch_id
        }
        let response = await apiCall("document/document/generate_forms", data)
        return response;

    },

    async generateMetaData(data)
    {
        let response = await apiCall("document/document/generate_meta_data", data)
        return response;

    },

    async getFSB()
    {
        let response = await apiCall("document/document/compute_resources", [])
        return response;

    },

}