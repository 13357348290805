import React from 'react';
import { ThemeProvider } from './ThemeContext';
import { FinderProvider } from './FinderContext';
import { BatchProvider } from './BatchContext';
import { DocumentProvider } from './DocumentContext';

const SuperProvider=(props)=>{
  
        return (
                 <ThemeProvider>
                   <FinderProvider>
                      <BatchProvider>
                        <DocumentProvider>
                          {props.children}
                        </DocumentProvider>
                      </BatchProvider>
                   </FinderProvider>
                  </ThemeProvider>
        )
}

export default SuperProvider;