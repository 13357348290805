import { Link } from "react-router-dom"
import NiraForm from "../../Components/Documents/NiraForm"
import AppContainter from "../../Components/Navigation/AppContainer"
import NiraMenu from "../../Components/Navigation/NiraMenu"
import { useContext } from "react"
import BatchContext from "../../Context/BatchContext"
import DocumentContext from "../../Context/DocumentContext"

function NiraProfilePage(){

    const {batchInfo} = useContext(BatchContext);
    const {metaData, currentPage, goToNextPage, goToPreviousPage} = useContext(DocumentContext);

    return <AppContainter 
                title="NIRA Profile"
                description="Generate barcodes and print NIRA forms"
                wideView
                hideHeaders={true}
                >

               <div className="d-block d-md-flex align-items-stretch h-100">
                    <NiraMenu/>

                    <div className="gallery-content-container">
                        <div data-scrollbar="true" data-height="100%">
                        <div className="gallery-content">
                          <div className="gallery">

                            {batchInfo && <div className="d-flex align-items-center mb-3">

                                <div className="gallery-title mb-0">
                                <Link to="#">
                                {batchInfo.batch_name} <i className="fa fa-chevron-right"></i>
                                <small>Created on {batchInfo.created_at.short_date} {batchInfo.created_at.time}. Contains {batchInfo.total_forms.total_p} form(s) | Starting Serial: {batchInfo.start_serial} | Ending Serial: {batchInfo.end_serial}</small>
                                </Link>
                                </div>


                               {metaData && <div className="ms-auto btn-group">
                                    <Link to="#" className="btn btn-outline-default btn-sm" onClick={goToPreviousPage}><i className="fa fa-angle-double-left"></i></Link>
                                    <Link to="#" className="btn btn-outline-default btn-sm">Page {currentPage}</Link>
                                    <Link to="#" className="btn btn-outline-default btn-sm">{metaData.total_pages} Pages</Link>
                                    <Link to="#" className="btn btn-outline-default btn-sm" onClick={goToNextPage}><i className="fa fa-angle-double-right"></i></Link>
                                </div>}

                            </div>}
                           </div>
                          </div>
                                <NiraForm />  
                        </div>
                    </div>
               </div>

                {/* <div className="row">
                     <NiraForm />
                </div> */}




            </AppContainter>

}


export default NiraProfilePage