import React, { useContext, useEffect, useState } from 'react';
import ajaxDocument from '../util/remote/ajaxDocument';
import BatchContext from './BatchContext';

const DocumentContext = React.createContext();

export const DocumentConsumer = DocumentContext.Consumer;

export const DocumentProvider = (props)=> {

 const [currentPage, setCurrentPage] = useState(1);
 const [metaData, setMetaData] = useState(false);
 const [data, setData] = useState({current_page: 1, batch_id: 0})
 const [finder, setFinder] = useState(false);
 const [FSB, setFSB] = useState("...");


 useEffect(()=>{
    fetchFSB();
    if(data.batch_id > 0){
        generateMetaData(data)
    }
 }, [data])

 useEffect(()=>{
    if(currentPage>1)
    {
        fetchMoreTrigger()
    }
}, [currentPage])

const fetchMoreTrigger=()=>{
    const newData={
        ...data,
        "current_page":currentPage,
    }
    generateMetaData(newData)
}

 const generateMetaData=async(nData)=>{
    const server_response = await ajaxDocument.generateMetaData(nData);
    if(server_response.status==='OK'){
        setMetaData(server_response.details);
    }else{
        setMetaData(false)
    }
 }

 const fetchFSB=async()=>{
    const server_response = await ajaxDocument.getFSB();
    if(server_response.status==='OK'){
        setFSB(server_response.details.total_p);
    }
 }

 const goToNextPage=()=>{
    if(currentPage < metaData.total_pages){
        setCurrentPage(currentPage + 1);
    }
 }

 const goToPreviousPage=()=>{
    if(currentPage > 1){
        setCurrentPage(currentPage - 1);
    }
 }

    
    return (
           <DocumentContext.Provider value={
               {
                currentPage,
                metaData,
                finder,
                FSB,
                goToNextPage,
                goToPreviousPage,
                generateMetaData,
                setData,
                setCurrentPage,
                setFinder,
                fetchFSB
               }
               }>
               {props.children}
           </DocumentContext.Provider>
        );
    
}

export default DocumentContext;