import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ThemeContext from '../../Context/ThemeContext';

const MenuItem = ({ iconClass, text, submenuItems, index }) => {
  const {activeMenu, setActiveMenu} = useContext(ThemeContext)
  

  useEffect(()=>{
    closeMenus(activeMenu)
  }, [activeMenu])

  const toggleMenu = (ind) => {
    if(ind === activeMenu){
        setActiveMenu(0)
    }else{
        setActiveMenu(ind);
    }
     
  };

  const closeMenus=(ind)=>{
    const menus = document.getElementsByClassName("has-sub");
    const aIndex = ind-1;
    for(var i=0; i<menus.length; i++){
        if(i!==aIndex){
            menus[i].classList.remove("active")
        }
    }
  }

  return (
    <div className={`menu-item has-sub ${activeMenu===index ? 'active' : ''}`}>
      <Link to="#" className="menu-link" onClick={()=>toggleMenu(index)}>
        <span className="menu-icon"><i className={`${iconClass}`}></i></span>
        <span className="menu-text">{text}</span>
        <span className="menu-caret"><b className="caret"></b></span>
      </Link>
      <div className={`menu-submenu ${activeMenu===index ? 'active' : ''}`}>
        {submenuItems.map((item, index) => (
          <div className="menu-item" key={index}>
            <Link to={item.link} className="menu-link">
              <span className="menu-text">{item.text}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuItem;
