import { useContext, useState } from "react";
import SystemModal from "../Common/SystemModal";
import ActivityLoader from "../Common/ActivityLoader";
import functions from "../../util/functions";
import TextInput from "../Common/Form/TextInput";
import dictionary from "../../util/dictionary";
import AlertError from "../Common/AlertError";
import DocumentContext from "../../Context/DocumentContext";

const SerialFinder =()=>{

    const [loading, setLoading]  = useState(false)
    const [info, setInfo] = useState(false)
    const [serial, setSerial] = useState("")
    const {setFinder} = useContext(DocumentContext);

    const onConfirm=async(e)=>{
        e.preventDefault()
        if(serial.length>0){
            setFinder(serial);
            functions.closePopup();
        }else{
            setInfo(<AlertError message={dictionary._completeFields}/>)
        }
    }



    const RenderFooter=()=>{

        if(loading){
            return <ActivityLoader/>
        }else{

            return <> 
                    <button type="button" className="btn btn-lg btn-default" onClick={functions.closePopup}>Close</button>
                    <button 
                        type="button" 
                        className={`btn btn-lg ripple btn-success`} 
                        onClick={onConfirm}>Find Serial</button>
                    </>
        }
    }


    return <SystemModal 
            title="Serial Finder"
            id={"modal-serial-finder"}
            footer={RenderFooter()}
    >

        <div className="">
               {info}
            <form method="post" onSubmit={onConfirm}>
               
                <TextInput
                    type="text"
                    value={serial}
                    label="Serial No"
                    placeholder="Enter form serial number"
                    onChange={(e)=>{setSerial(e.target.value)}}
                    />

               
                <small className="form-text text-muted">
                    You can find multiple serials by providing a string of serials separated with commas. E.g. 1000,2000,9000
                </small>

            </form>


        </div>

</SystemModal>

}


export default SerialFinder;