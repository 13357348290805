import React, { useEffect, useState } from 'react';
import ajaxDocument from '../util/remote/ajaxDocument';

const BatchContext = React.createContext();

export const BatchConsumer = BatchContext.Consumer;

export const BatchProvider = (props)=> {

 const [batchList, setBatchList] = useState(false);
 const [batchInfo, setBatchInfo] = useState(false);
 const [activeBatchId, setActiveBatch] = useState(false);

const getBatches=async()=>{
    setBatchList(false)
    const server_response = await ajaxDocument.fetchBatches();
    if(server_response.status==="OK"){
        setBatchList(server_response.details)
    }else{
        setBatchList("404")
    }
}
    
    return (
           <BatchContext.Provider value={
               {
                batchList,
                batchInfo,
                activeBatchId,
                setActiveBatch,
                getBatches,
                setBatchInfo,
                
               }
               }>
               {props.children}
           </BatchContext.Provider>
        );
    
}

export default BatchContext;