import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import AlertError from '../../Components/Common/AlertError'
import AlertSuccess from '../../Components/Common/AlertSuccess'
import logolg from '../../Images/logo.png'
import functions from '../../util/functions'
import ajaxUser from '../../util/remote/ajaxUser'



function LoginPage() { 


    const [info, setInfo] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const   onPressLogin = async (event) => {
        event.preventDefault();

        setInfo(<ActivityLoader />)
        if ( username.length > 0 && password.length > 0) {
            //fetch login from the API
            const server_response = await ajaxUser.loginUser(username, password);
    
            if (server_response.status === "OK") {
    
               
                localStorage.setItem('uppc@user', server_response.details);
    
                // decode the token to access some variables for use
                const access_token = localStorage.getItem('uppc@user');
                const decorded_token = functions.parseJwt(access_token);
                const is_secure = decorded_token['data']['is_secure'];

                setInfo(<AlertSuccess message={server_response.message} />)
    
                if (is_secure === "0") {
                    // this.props.history.push('/password-reset');
                    window.location.reload();
    
                
    
                } else {
                    // navigate('/');
                    window.location.reload();
    
                }
            } else {
    
                setInfo(<AlertError message={server_response.message} />)
                
    
            }
        } else {
               setInfo(<AlertError message={"Enter username and password"} />)
        }
    
    
    }




    return <div id="app" className="app app-full-height app-without-header">
                <div className="login">
                    <div className="login-content card p-4">
                        <form method="POST" name="login_form" onSubmit={onPressLogin}>
                            <div className='text-center'>
                                <img src={logolg} style={{width:"100px"}}/>
                            </div>
                            <h1 className="text-center">Sign In</h1>
                            <div className="text-muted text-center mb-4">
                                For your protection, please verify your identity.
                                {info}
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email Address</label>
                                <input 
                                    type="text" 
                                    className="form-control form-control-lg fs-15px" 
                                    value={username} 
                                    placeholder="Username"
                                    onChange={(e)=>setUsername(e.target.value)}
                                    />
                            </div>
                            <div className="mb-3">
                                <div className="d-flex">
                                    <label className="form-label">Password</label>
                                    <Link to="#" className="ms-auto text-muted">Forgot password?</Link>
                                    </div>
                                <input 
                                    type="password" 
                                    className="form-control form-control-lg fs-15px" 
                                    value={password} 
                                    placeholder="Enter your password" 
                                    onChange={(e)=>setPassword(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="customCheck1" />
                                    <label className="form-check-label fw-500" for="customCheck1">Remember me</label>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Sign In</button>
                            {/* <div className="text-center text-muted">
                            Don't have an account yet? <a href="page_register.html">Sign up</a>.
                            </div> */}
                        </form>
                    </div>

                </div>

            </div>
    

}

export default LoginPage