import { Link } from "react-router-dom"
import MenuItem from "./MenuItem";
import DashMenu from "./DashMenu";

const LeftSideBar=(props)=>{

    const activeRoute = window.location.pathname; 

    const handleDropdown=(e)=>{
        // e.preventDefault();


        // const drops = document.getElementsByClassName("has-sub");
        // for(var i=0; i<drops.length; i++){

        //     if(e.target.parentElement.parentElement !== drops[i]){
        //         drops[i].classList.remove("expand")
        //         drops[i].lastChild.classList.remove("expanded-menu")
        //     }
        // }


        // const Parent = e.target.parentElement.parentElement;
        // const Child = Parent.lastChild //Actual dropdown

        // Parent.classList.toggle("expand");
        // Child.classList.add("fade-menu")
        // setTimeout(() => {
        //     Child.classList.remove("fade-menu")
        //     Child.classList.toggle("expanded-menu")
        // }, 200);

        
        
    }

    

    return <div id="sidebar" className="app-sidebar">

    <div className="app-sidebar-content ps ps--active-y" data-scrollbar="true" data-height="100%">
    
    <div className="menu">
    <div className="menu-header">Dashboards</div>
  
    <DashMenu
        link='/'
        activeRoute={activeRoute}
        text='NIRA Generator'
        iconClass='fa fa-laptop'
        />
   

   
    <div className="menu-divider"></div>
    <div className="menu-header">Applications</div>
   

        <MenuItem 
                iconClass="fa fa-user"
                text="Profiles"
                submenuItems={[
                    {link:'#', text:'NIRA'},

                ]}
            index={1}
            />
        
        
    </div>
    
    </div>
    
    
    <button className="app-sidebar-mobile-backdrop" data-dismiss="sidebar-mobile"></button>
    
    </div>

}

export default LeftSideBar