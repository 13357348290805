import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
    async loginUser(username, password)
    {

        let data={
            "username":username,
            "password":password
        }
        let response = await apiCall("login", data)
        return response;

    },


    
   
}