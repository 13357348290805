import { useContext, useState } from "react";
import SystemModal from "../Common/SystemModal";
import ActivityLoader from "../Common/ActivityLoader";
import functions from "../../util/functions";
import TextInput from "../Common/Form/TextInput";
import dictionary from "../../util/dictionary";
import AlertError from "../Common/AlertError";
import AlertSuccess from "../Common/AlertSuccess";
import ajaxDocument from "../../util/remote/ajaxDocument";
import BatchContext from "../../Context/BatchContext";

const NewBatch =()=>{

    const [loading, setLoading]  = useState(false)
    const [info, setInfo] = useState(false)
    const [batchName, setbatchName] = useState("");
    const [startSerial, setstartSerial] = useState("")
    const {getBatches} = useContext(BatchContext);

    const resetForm=()=>{
        setbatchName("")
        setstartSerial("")
    }
    const onConfirm=async(e)=>{
        e.preventDefault()
        if(batchName.length>0 && startSerial.length>0){
            setLoading(true)
            const server_response = await ajaxDocument.postNewBatch(batchName, startSerial);
            setLoading(false)
            if(server_response.status==="OK"){
                setInfo(<AlertSuccess message={server_response.message}/>)
                resetForm()
                getBatches()
            }else{
                setInfo(<AlertError message={server_response.message}/>)
            }
        }else{
            setInfo(<AlertError message={dictionary._completeFields}/>)
        }
    }



    const RenderFooter=()=>{

        if(loading){
            return <ActivityLoader/>
        }else{

            return <> 
                    <button type="button" className="btn btn-lg btn-default" onClick={functions.closePopup}>Close</button>
                    <button 
                        type="button" 
                        className={`btn btn-lg ripple btn-success`} 
                        onClick={onConfirm}>Save Batch</button>
                    </>
        }
    }


    return <SystemModal 
            title="New Batch"
            id={"modal-new-batch"}
            footer={RenderFooter()}
    >

        <div className="">
               {info}
            <form method="post" onSubmit={onConfirm}>
                <TextInput
                    type="text"
                    value={batchName}
                    label="Batch Name"
                    placeholder="Enter batch name"
                    onChange={(e)=>{setbatchName(e.target.value)}}
                    />

                <TextInput
                    type="number"
                    value={startSerial}
                    label="Starting Serial"
                    placeholder="Enter Starting Serial"
                    onChange={(e)=>{setstartSerial(e.target.value)}}
                    />

               
                <small className="form-text text-muted">
                    After creating a batch, you can start generating forms specific to this batch.
                </small>

            </form>


        </div>

</SystemModal>

}


export default NewBatch;