import { useContext, useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import ActivityLoader from "../Common/ActivityLoader";
import functions from "../../util/functions";
import TextInput from "../Common/Form/TextInput";
import dictionary from "../../util/dictionary";
import AlertError from "../Common/AlertError";
import AlertSuccess from "../Common/AlertSuccess";
import ajaxDocument from "../../util/remote/ajaxDocument";
import BatchContext from "../../Context/BatchContext";

const FormGenerator =()=>{

    const [loading, setLoading]  = useState(false)
    const [info, setInfo] = useState(false)
    const [quantity, setquantity] = useState("")
    const {activeBatchId, getBatches} = useContext(BatchContext);

    useEffect(()=>{
        if(!activeBatchId){
            setInfo(<AlertError message="Please select a batch first for which you want to generate forms for!"/>)
        }
    }, [activeBatchId])

    
    const onConfirm=async(e)=>{
        e.preventDefault()
        if(quantity.length>0 && activeBatchId){
            setLoading(true)
            const server_response = await ajaxDocument.generateForms(quantity, activeBatchId);
            setLoading(false)
            if(server_response.status==="OK"){
                setInfo(<AlertSuccess message={server_response.message}/>)
                getBatches();
            }else{
                setInfo(<AlertError message={server_response.message}/>)
            }
        }else{
            setInfo(<AlertError message="Enter total forms for selected batch, then try again."/>)
        }
    }



    const RenderFooter=()=>{

        if(loading){
            return <ActivityLoader/>
        }else{

            return <> 
                    <button type="button" className="btn btn-lg btn-default" onClick={functions.closePopup}>Close</button>
                    <button 
                        type="button" 
                        className={`btn btn-lg ripple btn-success`} 
                        onClick={onConfirm}>Generate</button>
                    </>
        }
    }


    return <SystemModal 
            title="Form Generator"
            id={"modal-form-gen"}
            footer={RenderFooter()}
    >

        <div className="">
               {info}
            <form method="post" onSubmit={onConfirm}>
                
                <TextInput
                    type="number"
                    value={quantity}
                    label="Total Forms"
                    placeholder="Enter no. of forms to generate"
                    onChange={(e)=>{setquantity(e.target.value)}}
                    />

               
                <small className="form-text text-muted">
                    Caution! Big numbers can impact on the speed due to limited computing power.
                </small>

            </form>


        </div>

</SystemModal>

}


export default FormGenerator;