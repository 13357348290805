import React from 'react';
import { Link } from 'react-router-dom';

const DashMenu = ({ iconClass, text, activeRoute, link }) => {
  

  return (
    <div className={`menu-item ${activeRoute===link?'active':''}`}>
    <Link to={link} className="menu-link">
    <span className="menu-icon"><i className={iconClass}></i></span>
    <span className="menu-text">{text}</span>
    </Link>
    </div>
  );
};

export default DashMenu;
