const Toaster=(props)=>{


    return(
        <div className="toast fade show" data-autohide="false" id="toast-1">
        <div className="toast-header">
         <i className="far fa-bell text-muted me-2"></i>
         <strong className="me-auto">{props.title}</strong>
         <small>Just now</small>
         <button type="button" className="btn-close" data-bs-dismiss="toast"></button>
     </div>
     <div className="toast-body">
         {props.body}
     </div>
     </div>
    )


}

export default Toaster