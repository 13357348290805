import React from 'react';
import functions from '../../util/functions';


class SystemModal extends React.Component {
  state={
      loading:false
  }

  onConfirm=()=>{
    this.setState({
        loading:true
    }, ()=>this.props.onConfirm())
  }

  render(){
    
      return (
        <div 
          className="modal fade show sys-modal" id={this.props.id || "sys_modal"}
          style={{display: 'block'}}
          aria-modal="true"
          role="dialog"
          >
        <div className={`modal-dialog modal-dialog-centered ${this.props.type}`}>
            <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{this.props.title}</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={functions.closePopup}></button>
            </div>
                
                <div className="modal-body">
                   {this.props.children}
                </div>

                 <div className="modal-footer justify-content-between">
                   {this.props.footer}
                </div>


            </div>
        </div>
    </div>
      )
    
 
  }
}

export default SystemModal;