import './App.css';
import { Route, BrowserRouter as Router, Routes as Switch, Navigate} from 'react-router-dom'
import SuperProvider from './Context/SuperProvider';
import LoginPage from './Pages/User/LoginPage';
import { useEffect, useState } from 'react';
import NiraProfilePage from './Pages/Dashboard/NiraProfilePage';


function App() {

  const [loggedIn, setLoggedIn] = useState(true)

  useEffect(()=>{
    checkLogin()
  }, [])

  const checkLogin=()=>{
    if (!localStorage.getItem("uppc@user")) {
       setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }


  return (
    <SuperProvider>
    <Router forceRefresh={false}>
    <Switch>
      
        {!loggedIn && <>
                      <Route path="/" element={<LoginPage/>}/>
                    </>}
        {loggedIn && <>
                      <Route path="/" element={<NiraProfilePage/>}/>

                    </> }


    </Switch>
    </Router>
    </SuperProvider>
  );
}

export default App;
