import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   
    async fetchSearchResults(hint)
    {
        let response = await apiCall("find", {hint:hint})
        return response;

    },


}